import React, { useRef } from 'react';
import { Box } from 'system';
import * as A from 'system/animated';
import { useSpring, useChain } from 'react-spring';
import { withTheme } from 'styled-components';
import { withParallax } from 'hooks/useParallax';

function LineHeading({
  theme,
  direction = `ltr`,
  width = `100%`,
  textColor = null,
  lineColor = null,
  scrollTop,
  offsetY,
  inView,
  style = {},
  label = ``,
  ...rest
}) {
  const isRTL = direction === `rtl`;
  const lineRef = useRef();
  const lineSpring = useSpring({
    ref: lineRef,
    config: { mass: 1, tension: 250, friction: 20, clamp: true },
    transform: inView
      ? 'translate3d(0,0,0)'
      : direction === 'rtl'
      ? 'translate3d(100%,0,0)'
      : 'translate3d(-100%,0,0)',
    from: {
      transform:
        direction === 'rtl'
          ? 'translate3d(100%,0,0)'
          : 'translate3d(-100%,0,0)',
    },
  });

  const textRef = useRef();
  const textSpring = useSpring({
    ref: textRef,
    opacity: inView ? 1 : 0,
    from: {
      opacity: 0,
    },
  });
  useChain([lineRef, textRef]);

  return (
    <A.Flex
      width={width || 1}
      alignItems={`center`}
      style={{
        ...style,
        overflow: `hidden`,
        transform:
          scrollTop && scrollTop.to
            ? scrollTop.to(scrollTop => {
                return `translate3d(0, ${scrollTop * offsetY}px, 0)`;
              })
            : null,
      }}
      {...rest}
    >
      <Box order={isRTL ? 2 : 1} as={`figure`} flex={1} height={1} m={0} p={0}>
        <A.Box
          as={`figure`}
          width={1}
          height={1}
          bg={lineColor || theme.colors.greyDark}
          m={0}
          p={0}
          mt={`2px`}
          style={{ ...lineSpring, margin: 0, padding: 0 }}
          aria-hidden="true"
        />
      </Box>
      <A.Box ml={isRTL ? 0 : 10} mr={isRTL ? 10 : 0} order={isRTL ? 1 : 2}>
        <A.Text
          as={`span`}
          lineHeight={1}
          fontSize={[16, 16, 18]}
          height={[16, 16, 18]}
          color={textColor || theme.colors.blue}
          style={{
            textTransform: `uppercase`,
            whiteSpace: `nowrap`,
            verticalAlign: `middle`,
            ...textSpring,
          }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </A.Box>
    </A.Flex>
  );
}

export default withTheme(withParallax(LineHeading));
