import { useEffect, useState, useRef } from 'react';

function useIntersectionObserver(
  ref,
  options = {
    threshold: undefined,
    root: null,
    rootMargin: `-25% 0% -25% 0%`,
    triggerOnce: false,
  },
  debug = false
) {
  const observer = useRef();
  // configure the state
  const [state, setState] = useState({
    inView: false,
    triggered: false,
    entry: undefined,
  });
  if (!options)
    options = {
      threshold: 0,
      root: null,
      rootMargin: `-25% 0% -25% 0%`,
      triggerOnce: false,
    };
  const { threshold, root, rootMargin, triggerOnce } = options;

  useEffect(() => {
    // check that the element exists, and has not already been triggered
    if (ref.current && !state.triggered) {
      observer.current = new IntersectionObserver(
        (entries, observerInstance) => {
          // checks to see if the element is intersecting
          entries.forEach(entry => {
            if (triggerOnce && state.triggered) {
              return;
            }
            if (entry.intersectionRatio > 0) {
              // if (debug) {
              //   console.log(entry);
              // }
              // if it is update the state, we set triggered as to not re-observe the element
              setState({
                inView: true,
                triggered: true,
                entry: observerInstance,
              });
              if (triggerOnce) {
                observerInstance.unobserve(ref.current);
                observer.current.disconnect();
              }
            } else {
              if (triggerOnce) {
                return;
              }
              setState({
                inView: false,
                triggered: true,
                entry: observerInstance,
              });
            }
          });
        },
        {
          threshold:
            threshold !== undefined
              ? threshold
              : [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
          root: root || null,
          rootMargin: rootMargin || '0%',
          delay: 100,
          trackVisibility: true,
        }
      );
      observer.current.observe(ref.current);
    }
    return () => {
      if (observer.current && state.triggerOnce && state.triggered) {
        observer.current.disconnect();
      }
    };
  }, [
    rootMargin,
    triggerOnce,
    threshold,
    state.triggered,
    state.triggerOnce,
    debug,
    ref,
    root,
  ]);
  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return [state.inView, state.entry];
}

export default useIntersectionObserver;
