import theme from 'theme';
import css from '@styled-system/css';

export const body = { fontSize: [12] };
export const h1 = {
  as: `h1`,
  fontFamily: theme.fonts.headings,
  fontWeight: 700,
  fontSize: [36, 44, 52, 64, 70],
  lineHeight: `1.173em`,
  marginBottom: `0.25em`,
};

export const h2 = {
  as: `h2`,
  fontFamily: theme.fonts.headings,
  fontWeight: 700,
  fontSize: [30, 38, 46, 54],
  lineHeight: `1em`,
  marginBottom: `0.8em`,
};

export const h3 = {
  as: `h3`,
  fontFamily: theme.fonts.headings,
  fontWeight: 700,
  fontSize: [21, 24, 28, 32, 38],
  lineHeight: `1.15789473684`,
  marginBottom: `1.15789473684em`,
};

export const h4 = {
  as: `h4`,
  fontFamily: theme.fonts.headings,
  fontWeight: 700,
  fontSize: [18, 21, 24, 26, 28],
  lineHeight: `1.21428571429em`,
  marginBottom: `0.8em`,
};

export const h5 = {
  as: `h5`,
  fontFamily: theme.fonts.headings,
  fontWeight: 700,
  fontSize: [18, 18, 18, 20, 22],
  lineHeight: `1.26em`,
  marginBottom: `0.8em`,
};

export const h6 = {
  as: `h6`,
  fontFamily: theme.fonts.headings,
  fontWeight: 700,
  fontSize: [16, 16, 18],
  lineHeight: `1em`,
};

export const headings = [h1, h2, h3, h4, h5, h6];
export const headingsCss = headings.map(h => {
  let ns = `& ${h.as}`;
  delete h.as;
  return css({ [ns]: { ...h } });
});
