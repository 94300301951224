import React from 'react';
import { withParallax } from 'hooks/useParallax';
import { Box as AnimatedBox } from 'system/animated';
import { withTheme } from 'styled-components';
import { useSpring, to } from 'react-spring';

function Line({
  theme,
  color,
  direction = `ltr`,
  scrollTop,
  inView,
  offsetY = 0,
  style,
  ...rest
}) {
  const inViewProps = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 100 * offsetY,
    config: { mass: 1, tension: 280, friction: 60, clamp: true },
    from: { opacity: 0, y: 100 * offsetY }
  });

  const spring = useSpring({
    delay: 300,
    x: inView ? 0 : direction === `rtl` ? 100 : -100,
    config: { mass: 1, tension: 210, friction: 20, clamp: true }
  });

  return (
    <AnimatedBox
      componentProps={{ as: `figure` }}
      width={1}
      style={{
        ...style,
        overflow: `hidden`,
        opacity: inViewProps.opacity,
        transform: to([scrollTop, inViewProps.y], (scrollTop, ivy) => {
          return `translate3d(0, ${scrollTop * offsetY + ivy}px, 0)`;
        })
      }}
      {...rest}
    >
      <AnimatedBox
        bg={color || theme.colors.blue}
        width={1}
        height={`1px`}
        style={{
          transform: spring.x.to(x => `translate3d(${x}%, 0, 0)`)
        }}
      />
    </AnimatedBox>
  );
}

export default withTheme(withParallax(Line));
