import Line from 'components/Line';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-gtag';
import {withGlobals} from 'globals';
import {ParallaxChild, ParallaxContainer, withParallaxProvider,} from 'hooks/useParallax';
import React from 'react';
import {animated} from 'react-spring';
import {withTheme} from 'styled-components';

import {Box, Text} from 'system';
import * as A from 'system/animated';
import {Text as AnimatedText} from 'system/animated';
import {Column, Container, Row} from 'system/layout';
import {resolveLinkByOriginalId} from 'utils/resolveLink';
import LineHeading from './LineHeading';

const BG_TOP = [100, 150, 200, 250];
const CUT_HEIGHT = [150, 150, 200, 275, 325];

const BG = withParallaxProvider(({theme, parallax}) => {
	const pts = parallax.scrollTop.to((st) => {
		const t = Math.round(st * 0.1);
		const pos = t < 0 ? 0 : Math.abs(t, 0);
		return `100,${pos} 0,100 100,100`;
	});

	return (
		<A.Absolute
			zIndex={0}
			top={BG_TOP.map((value) => Math.round(-value * 0.25))}
			left={0}
			width={1}
			height={BG_TOP.map((value) => `calc(100% + ${value}px)`)}
			display={`flex`}
			flexDirection={`column`}
		>
			<Box width={1} height={CUT_HEIGHT}>
				<Box
					as={`svg`}
					width={1}
					height={`100%`}
					viewBox={`0 0 100 100`}
					preserveAspectRatio={`none`}
				>
					<animated.polygon fill={theme.colors.grey} points={pts}/>
				</Box>
			</Box>
			<Box width={1} flex={1} bg={theme.colors.grey}/>
		</A.Absolute>
	);
});

function FooterLink({theme, children, ...rest}) {
	return (
		<Text
			as={rest?.as || OutboundLink}
			color={theme.colors.blue}
			fontSize={[16, 18]}
			style={{
				textDecoration: `none`,
				overflow: `hidden`,
				verticalAlign: `bottom`,
				display: `inline-block`,
				flex: `none`,
				alginSelf: `flex-end`,
				width: `auto`,
			}}
			css={`
                :before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 0.1em;
                    bottom: 0.03em;
                    left: 0;
                    background-color: ${theme.colors.blue};
                    transition: left 0.6s ease-out;
                }

                :after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 0.1em;
                    left: 200%;
                    bottom: 0.03em;
                    background-color: ${theme.colors.blue};
                    transition: left 0.6s ease-out;
                }

                :hover:before,
                :focus:before {
                    left: -200%;
                }

                :hover:after,
                :focus:after {
                    left: 0;
			`}
			{...rest}
		>
			{children}
		</Text>
	);
}

function SocialLink({theme, ...props}) {
	return (
		<div>
			<Text
				as={OutboundLink}
				color={theme.colors.blue}
				fontSize={[14, 18, 21, 24, 28]}
				fontWeight={`bold`}
				mb={`5px`}
				style={{
					textDecoration: `none`,
					overflow: `hidden`,
					verticalAlign: `bottom`,
					display: `inline-block`,
				}}
				css={`
                    :before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 0.1em;
                        bottom: 0.03em;
                        left: 0;
                        background-color: ${theme.colors.blue};
                        transition: left 0.6s ease-out;
                    }

                    :after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 0.1em;
                        left: 200%;
                        bottom: 0.03em;
                        background-color: ${theme.colors.blue};
                        transition: left 0.6s ease-out;
                    }

                    :hover:before,
                    :focus:before {
                        left: -200%;
                    }

                    :hover:after,
                    :focus:after {
                        left: 0;
				`}
				{...props}
			/>
		</div>
	);
}

function Footer({
	                theme,
	                ref,
	                parallax,
	                inView,
	                data,
	                showBg = false,
	                locale,
	                ...rest
                }) {
	const {
		callout,
		contactPageParagraphNode,
		facebookUrl,
		twitterUrl,
		linkedinUrl,
		copyright,
		ethicsUrl,
		privacyLegalUrl,
		gdprUrl,
		accessibilityPolicyUrl,
		aodaUrl
	} = data;

	const privacyLink = resolveLinkByOriginalId('IuysVGymRfqBGclpWtZW_Q', locale);
	return (
		<ParallaxContainer
			normal={0.5}
			ref={ref}
			pt={0}
			pb={0}
			style={{position: `relative`}}
			{...rest}
		>
			{showBg && <BG theme={theme}/>}
			<Container>
				<Row>
					<ParallaxChild
						as={Column}
						width={[3 / 4, 3 / 4, 7 / 12]}
						ml={[
							`${(1 / 4) * 100}%`,
							`${(1 / 4) * 100}%`,
							`${(5 / 12) * 100}%`,
						]}
						offsetY={0.025}
					>
						<LineHeading label={callout} direction={`rtl`}/>
					</ParallaxChild>
					<ParallaxChild
						as={Column}
						width={[3 / 4, 3 / 4, 1 / 3]}
						offsetY={0.05}
					>
						<Text
							as={`article`}
							mt={[40, 60, 80, 100, 120]}
							mb={[40, 60, 80, 100, 120]}
							fontSize={[21, 24, 30, 36, 42]}
							fontFamily={theme.fonts.headings}
							color={theme.colors.blue}
							css={`
                                & a {
                                    font-weight: bold;
                                    color: ${theme.colors.blue};
                                    position: relative;
                                    text-decoration: none;
                                    overflow: hidden;
                                    vertical-align: bottom;
                                    display: inline-block;
                                }

                                & a:before {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 0.1em;
                                    bottom: 0.05em;
                                    left: 0;
                                    background-color: ${theme.colors.blue};
                                    transition: left 0.6s ease-out;
                                }

                                & a:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 0.1em;
                                    left: 200%;
                                    bottom: 0.05em;
                                    background-color: ${theme.colors.blue};
                                    transition: left 0.6s ease-out;
                                }

                                & a:hover:before,
                                & a:focus:before {
                                    left: -200%;
                                }

                                & a:hover:after,
                                & a:focus:after {
                                    left: 0;
                                }

                                & p {
                                    line-height: 1.14285;
                                }
							`}
							dangerouslySetInnerHTML={{
								__html: contactPageParagraphNode.childMarkdownRemark.html,
							}}
						/>
					</ParallaxChild>
					<ParallaxChild
						as={Column}
						width={[1 / 2, 1 / 2, 1 / 3]}
						ml={[`${(1 / 2) * 100}%`, `${(1 / 2) * 100}%`, `${(2 / 3) * 100}%`]}
						display={`flex`}
						style={{flexDirection: `column`}}
						offsetY={0.08}
					>
						{facebookUrl && (
							<SocialLink theme={theme} title={`Facebook`} href={facebookUrl}>
								Facebook
							</SocialLink>
						)}
						{linkedinUrl && (
							<SocialLink theme={theme} title={`LinkedIn`} href={linkedinUrl}>
								LinkedIn
							</SocialLink>
						)}
						{twitterUrl && (
							<SocialLink theme={theme} title={`Twitter`} href={twitterUrl}>
								Twitter
							</SocialLink>
						)}
					</ParallaxChild>
				</Row>
				<Line direction={`ltr`} mt={[40, 60, 80, 100, 120]} offsetY={0.1}/>
				<Row>
					<Column width={[1 / 2, 1 / 2, `175px`]}>
						<ParallaxChild
							as={AnimatedText}
							offsetY={[0.05, 0.05, 0.05, 0.1]}
							fontSize={[12, 15, 18, 21]}
							mb={[30, 30, 30, 20]}
						>
							{copyright}
						</ParallaxChild>
					</Column>
					<Column width={[1 / 2, 1 / 2, `calc(100% - 175px)`]} style={{textAlign: `right`}}>
						<ParallaxChild
							as={AnimatedText}
							offsetY={[0.05, 0.05, 0.05, 0.1]}
							fontSize={[12, 15, 18, 21]}
							mr={[0, 0, -14, -18]}
							mb={[30, 30, 30, 20]}
							display={`flex`}
							flexDirection={[`column`, `column`, `row`]}
							flexWrap={`wrap`}
							alignItems={`flex-end`}
							justifyContent={`flex-end`}
						>
							<FooterLink
								theme={theme}
								as={OutboundLink}
								href={ethicsUrl}
								target="_blank"
								rel="noopener noreferrer"
								mx={[0, 0, 7, 9]}
							>
								Ethics Report
							</FooterLink>
							<FooterLink
								theme={theme}
								as={Link}
								to={privacyLink.url}
								mx={[0, 0, 7, 9]}
							>
								{privacyLink.title}
							</FooterLink>
							<FooterLink
								theme={theme}
								as={OutboundLink}
								href={gdprUrl}
								target="_blank"
								rel="noopener noreferrer"
								mx={[0, 0, 7, 9]}
							>
								GDPR Policy
							</FooterLink>
							<FooterLink
								theme={theme}
								as={OutboundLink}
								href={accessibilityPolicyUrl}
								target="_blank"
								rel="noopener noreferrer"
								mx={[0, 0, 7, 9]}
							>
								{locale === 'fr' ? `Politique d'accessibilité` : `Accessibility Policy`}
							</FooterLink>
							<FooterLink
								theme={theme}
								as={OutboundLink}
								href={aodaUrl}
								target="_blank"
								rel="noopener noreferrer"
								mx={[0, 0, 7, 9]}
							>
								{locale === 'fr' ? `Plan pluriannuel de la LAPHO` : `AODA Multi-Year Plan`}
							</FooterLink>
							<FooterLink
								as={`a`}
								theme={theme}
								href={`javascript:Didomi.preferences.show()`}
								mx={[0, 0, 7, 9]}
							>
								{locale === 'fr' ? `Options de consentement` : `Consent choices`}
							</FooterLink>
						</ParallaxChild>
					</Column>
				</Row>
			</Container>
		</ParallaxContainer>
	);
}

export default withGlobals(withTheme(Footer))
