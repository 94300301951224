import mitt from 'mitt';
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { to, useSpring } from 'react-spring';
import * as A from 'system/animated';
import { Section } from 'system/layout';
import useBreakpoints from './useBreakpoints';
import useIntersectionObserver from './useIntersectionObserver';
const DEFAULT_NORMAL = [0.25, 0.25, 0.15];
const ParallaxContext = React.createContext({ st: 0, xy: [0, 0] });
ParallaxContext.displayName = `Parallax`;

function findNearestInBreakpointArray(arr, idx) {
  let i = idx;
  if (idx > arr.length - 1) {
    i = arr.length - 1;
  }
  return arr[i];
}

function useParallax(ref, normal = 0, springConfig = null, debug = false) {
  useParallax.__init();
  const scrollFrame = useRef(0);
  const normals = useMemo(() => (Array.isArray(normal) ? normal : [normal]), [
    normal,
  ]);
  const normalRef = useRef(normals[0] || 0);

  const onBreakpointChange = bp => {
    normalRef.current = findNearestInBreakpointArray(normals, bp);
  };
  useBreakpoints(null, onBreakpointChange, true);

  const calculateScrollTop = useCallback(() => {
    // console.log('cst', ref.current, window.innerHeight, normals);
    return ref.current
      ? ref.current.getBoundingClientRect().top -
          window.innerHeight * normalRef.current
      : 0;
  }, [ref]);

  const [parallax, set] = useSpring(() => ({
    scrollTop: calculateScrollTop(),
    config: springConfig || { mass: 1, tension: 280, friction: 60 },
  }));

  const scrollHandler = useCallback(
    (e, immediate = false) => {
      cancelAnimationFrame(scrollFrame.current);

      scrollFrame.current = requestAnimationFrame(() => {
        if (debug) console.log(calculateScrollTop());
        if (ref.current) {
          set({
            scrollTop: calculateScrollTop(),
            immediate,
          });
        }
      });
    },
    [calculateScrollTop, debug, ref, set]
  );

  useEffect(() => {
    scrollHandler(null, true);
    return () => {
      if (scrollFrame.current) {
        cancelAnimationFrame(scrollFrame.current);
      }
    };
  }, [scrollHandler]);

  useEffect(() => {
    // useParallax.__emitter.on('resize', resizeHandler);
    useParallax.__emitter.on('scroll', scrollHandler);
    scrollHandler(null, true);
    return () => {
      // useParallax.__emitter.off('resize', resizeHandler);
      useParallax.__emitter.off('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  return parallax;
}

useParallax.__emitter = null;
useParallax.__initted = false;

useParallax.__init = function() {
  if (typeof window === `undefined` || useParallax.__initted) {
    return;
  }
  useParallax.__initted = true;
  useParallax.__emitter = mitt();

  window.__useParallaxActualWidth = window.innerWidth;
  window.__useParallaxActualHeight = window.innerHeight;

  window.addEventListener('resize', () => {
    if (
      window.innerWidth !== window.__useParallaxActualWidth ||
      window.innerHeight !== window.__useParallaxActualHeight
    ) {
      window.__useParallaxActualWidth = window.innerWidth;
      window.__useParallaxActualHeight = window.innerHeight;
      useParallax.__emitter.emit(`resize`, {
        width: window.__useParallaxActualWidth,
        height: window.__useParallaxActualHeight,
      });
    }
  });

  window.addEventListener(
    'scroll',
    e => {
      useParallax.__emitter.emit(`scroll`, e);
    },
    { passive: true, capture: false }
  );
};

function ParallaxContainer({
  as = Section,
  style = {},
  offsetY = 0,
  normal = DEFAULT_NORMAL,
  debug = false,
  componentProps = {},
  springConfig = null,
  ...rest
}) {
  const Component = as || A.Box;
  const ref = useRef();
  const parallax = useParallax(ref, normal, springConfig, debug);

  const offsetYs = useMemo(
    () => (Array.isArray(offsetY) ? offsetY : [offsetY]),
    [offsetY]
  );
  const offsetYRef = useRef(offsetYs[0]);
  const onBreakpointChange = bp => {
    offsetYRef.current = findNearestInBreakpointArray(offsetYs, bp);
  };
  useBreakpoints(null, onBreakpointChange, true);

  const [ioState, setIoState] = useState(
    typeof window === `undefined`
      ? {
          threshold: 0,
          rootMargin: `-25% 0% -25% 0%`,
          triggerOnce: true,
        }
      : {
          threshold: 0,
          rootMargin:
            window.innerHeight > 700 ? `-25% 0% -25% 0%` : `-10% 0% -10% 0%`,
          triggerOnce: true,
        }
  );

  const resizeHandler = () => {
    setIoState({
      threshold: 0,
      rootMargin:
        window.innerHeight > 700 ? `-25% 0% -25% 0%` : `-10% 0% -10% 0%`,
      triggerOnce: true,
    });
  };

  useEffect(() => {
    useParallax.__emitter.on(`resize`, resizeHandler);
    return () => {
      useParallax.__emitter.off(`resize`, resizeHandler);
    };
  });

  const [inView] = useIntersectionObserver(ref, ioState);

  if (!Component) {
    return null;
  }

  return (
    <ParallaxContext.Provider value={{ ...parallax, inView }}>
      <Component
        ref={ref}
        style={{
          transform: parallax.scrollTop.to(
            scrollTop =>
              `translate3d(0, ${scrollTop * offsetYRef.current}px, 0)`
          ),
          ...style,
        }}
        {...rest}
        {...componentProps}
      />
    </ParallaxContext.Provider>
  );
}

function ParallaxChild({
  as = A.Box,
  style = {},
  initialY = 50,
  offsetY = 0,
  componentProps = {},
  debug = false,
  ...rest
}) {
  const Component = useMemo(() => as || A.Box, [as]);
  const { scrollTop, inView } = useContext(ParallaxContext);
  if (debug) {
    console.log(inView);
  }
  const offsetYs = useMemo(
    () => (Array.isArray(offsetY) ? offsetY : [offsetY]),
    [offsetY]
  );
  const offsetYRef = useRef(offsetYs[0] * 0.5);
  const onBreakpointChange = bp => {
    offsetYRef.current = findNearestInBreakpointArray(offsetYs, bp) * 0.5;
  };
  useBreakpoints(null, onBreakpointChange, true);

  const inViewProps = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 100 * offsetYRef.current + initialY,
    config: { mass: 1, tension: 200, friction: 80, clamp: true },
    from: { opacity: 0, y: 10 },
  });

  return (
    <Component
      className={`parallax-child`}
      style={{
        opacity: inViewProps.opacity,
        transform: to([scrollTop, inViewProps.y], (scrollTop, ivy) => {
          return `translate3d(0, ${scrollTop * offsetYRef.current + ivy}px, 0)`;
        }),
        ...style,
      }}
      {...rest}
      {...componentProps}
    />
  );
}

const withParallax = FunctionalComponent => ({ offsetY, ...rest }) => {
  const { scrollTop, inView } = useContext(ParallaxContext);
  const offsetYs = useMemo(
    () => (Array.isArray(offsetY) ? offsetY : [offsetY]),
    [offsetY]
  );
  const offsetYRef = useRef(offsetYs[0] * 0.5);
  const onBreakpointChange = bp => {
    offsetYRef.current = findNearestInBreakpointArray(offsetYs, bp) * 0.5;
  };
  useBreakpoints(null, onBreakpointChange, true);
  return (
    <FunctionalComponent
      scrollTop={scrollTop}
      inView={inView}
      offsetY={offsetYRef.current}
      className={`parallax-child`}
      {...rest}
    />
  );
};

const withParallaxProvider = FunctionalComponent => ({
  normal = 0,
  offsetY = 0,
  springConfig = null,
  ...rest
}) => {
  const ref = useRef();
  const parallax = useParallax(ref, normal, springConfig);
  const offsetYs = useMemo(
    () => (Array.isArray(offsetY) ? offsetY : [offsetY]),
    [offsetY]
  );
  const offsetYRef = useRef(offsetYs[0] * 0.5);
  const onBreakpointChange = bp => {
    offsetYRef.current = findNearestInBreakpointArray(offsetYs, bp) * 0.5;
  };
  useBreakpoints(null, onBreakpointChange, true);

  const [ioState, setIoState] = useState(
    typeof window === `undefined`
      ? {
          threshold: 0,
          rootMargin: `-25% 0% -25% 0%`,
          triggerOnce: true,
        }
      : {
          threshold: 0,
          rootMargin:
            window.innerHeight > 700 ? `-25% 0% -25% 0%` : `-10% 0% -10% 0%`,
          triggerOnce: true,
        }
  );

  const resizeHandler = () => {
    setIoState({
      threshold: 0,
      rootMargin:
        window.innerHeight > 700 ? `-25% 0% -25% 0%` : `-10% 0% -10% 0%`,
      triggerOnce: true,
    });
  };

  useEffect(() => {
    useParallax.__emitter.on(`resize`, resizeHandler);
    return () => {
      useParallax.__emitter.off(`resize`, resizeHandler);
    };
  });

  const [inView] = useIntersectionObserver(ref, ioState);

  return (
    <ParallaxContext.Provider value={{ ...parallax, inView }}>
      {FunctionalComponent.call(null, {
        ref,
        inView,
        offsetY: offsetYRef.current,
        parallax,
        ...rest,
      })}
    </ParallaxContext.Provider>
  );
};

export {
  ParallaxContext,
  withParallaxProvider,
  withParallax,
  ParallaxContainer,
  ParallaxChild,
};

export default useParallax;
